<template>
  <div style="min-height: 100vh">
    <navbar @asideFlagShow="asideFlagShow"></navbar>
    <div class="main">
      <div class="top-card">
        <div class="name">
          <van-image
            width="31px"
            height="31px"
            :src="require('@/assets/cls/logo.png')"
          />
          <span>CLS</span>
        </div>
        <div class="price">
          <div>
          {{ $t("deal.price") }}: ${{ setting.cls_price }}

          </div>
          <div>
          {{ $t("deal.pancakePrice") }}: ${{ info.pancakePrice }}

          </div>
        </div>
      </div>
      <div class="overview">
        <div class="conversion">
          <div>
            <div>{{info.un_tiqu}}</div>
            <div>{{ $t("deal.num") }}(CLS)</div>
            <div>{{ $t("deal.minimum") + setting.cls_tiqu }}CLS</div>
          </div>
          <div>
          <van-button type="default" size="small" color="#00A8FF" @click="withDrawHandle" style="margin-right:10px">{{ $t("extract.extract") }}</van-button>

            <van-button type="default" size="small" color="#E6A23C" @click="toRecord">{{ $t("deal.record") }}</van-button>

          </div>
        </div>
        <div class="quota">
          <div>{{ $t("deal.quota") }}:</div>
          <div>{{ info.send_quota}} USDT</div>
        </div>
        <div class="quota">
          <div>{{ $t("deal.usequota") }}:</div>
          <div>{{ info.used_quota }} USDT</div>
        </div>
      </div>
      <div class="bottom-card">
        <!-- 切换区域 -->
        <div class="currency" v-if="flag">
          <div>
            <van-image
              width="26px"
              height="26px"
              :src="require('@/assets/cls/logo.png')"
            />
            <span>CLS</span>
          </div>
          <div>{{ $t("deal.available") }}: {{ CLS.amount }}</div>
        </div>
        <!-- -- -->
        <div class="currency" v-else>
          <div>
            <van-image
              width="26px"
              height="26px"
              :src="require('@/assets/cls/usdt-logo.png')"
            />
            <span>USDT</span>
          </div>
          <div>{{ $t("deal.usr") }}: {{ USDT.avai_quota }}</div>
        </div>
        <!-- 切换区域 -->
        <van-field
          type="textarea"
          maxlength="20"
          @input="inputHandle"
          :autosize="false"
          v-model="value1"
          :placeholder="$t('deal.input')"
        >
          <template #extra>
            <div class="btns">
              <div
                class="btn"
                v-for="(item, i) in btnVal"
                @click="btnHandle(i)"
                :key="item"
              >
                {{ item }}
              </div>
            </div>
          </template>
        </van-field>
        <div
          class="change-btn"
          @click="
            flag = !flag;
            value1 = '';
            value2 = '';
            fee = '';
          "
        >
          <van-image
            width="14px"
            height="14px"
            :src="require('@/assets/cls/change.png')"
          />
        </div>
        <!-- 切换区域 -->
        <div class="currency" v-if="flag">
          <div>
            <van-image
              width="26px"
              height="26px"
              :src="require('@/assets/cls/usdt-logo.png')"
            />
            <span>USDT</span>
          </div>
          <div>{{ $t("deal.available") }}: {{ USDT.amount }}</div>
        </div>
        <!-- -- -->
        <div class="currency" v-else>
          <div>
            <van-image
              width="26px"
              height="26px"
              :src="require('@/assets/cls/logo.png')"
            />
            <span>CLS</span>
          </div>
          <div>{{ $t("deal.available") }}: {{ CLS.amount }}</div>
        </div>
        <!-- 切换区域 -->
        <van-field
          v-model="value2"
          placeholder="0.00"
          :disabled="true"
          style="padding: 14px 10px; margin-bottom: 47px"
        />
        <div class="price" v-if="flag">
          {{ $t("deal.transaction") }}：1 CLS= {{ setting.cls_price }} USDT
        </div>
        <div class="price" v-else>
          {{ $t("deal.transaction") }}：1 USDT= {{ 1 / setting.cls_price }} CLS
        </div>
        <div class="service">{{ $t("deal.formalities") }}：{{ fee }} CLS</div>
        <van-button color="#00A8FF" style="width: 100%" @click="sub">{{
          $t("deal.submit")
        }}</van-button>
      </div>
    </div>
    <asidePopup
      @asideFlagShow="asideFlagShow"
      style="text-align: left"
      :asideFlag="asideFlag"
    ></asidePopup>
  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components: { asidePopup },
  data() {
    return {
      asideFlag: false,
      value1: "",
      value2: "",
      USDT: {},
      CLS: {},
      info: {},
      setting: [],
      fee: 0,
      flag: true,
      btnVal: ["25%", "50%", "75%", "100%"],
    };
  },
  methods: {
    // 提取
withDrawHandle(){
  this.$dialog
          .confirm({
            title: "提取",
            message: `是否确认提取$${this.info.un_tiqu}?`,
            confirmButtonColor: "#00A8FF"
          })
          .then(() => {
            
            this.$axios.post(this.$api.tiqu, {}).then(res => {
         this.getMyEarn()
      });
          })
          .catch(() => {
            // on cancel
          });
},
    // 确认兑换
    sub() {
      this.$dialog
        .confirm({
          title: this.$t("deal.exchange"),
          message: this.$t("deal.aotoExchange"),
          confirmButtonColor: "#00A8FF",
          confirmButtonText: this.$t("withdraw.submit"),
          cancelButtonText: this.$t("withdraw.cancel"),
        })
        .then(() => {
          this.$axios
            .post(this.$api.exchange, {
              type: this.flag ? "2" : "1",
              amount: this.value1,
            })
            .then(res => {
              this.value1 = ''
              this.fee=0
              this.value2 = ''
              this.$toast(res.msg)
              this.getMyEarn()
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 输入框选值按钮点击
    btnHandle(i) {
      if (this.flag) {
        switch (i) {
          case 0:
            this.value1 = this.CLS.amount * 0.25;
            break;
          case 1:
            this.value1 = this.CLS.amount * 0.5;
            break;
          case 2:
            this.value1 = this.CLS.amount * 0.75;
            break;
          case 3:
            this.value1 = this.CLS.amount * 1;
            break;
        }
      } else {
        switch (i) {
          case 0:
            this.value1 = this.USDT.avai_quota * 0.25;
            break;
          case 1:
            this.value1 = this.USDT.avai_quota * 0.5;
            break;
          case 2:
            this.value1 = this.USDT.avai_quota * 0.75;
            break;
          case 3:
            this.value1 = this.USDT.avai_quota * 1;
            break;
        }
      }
    },
    // 输入
    inputHandle() {
      if (this.flag) {
        this.value2 = this.value1 * this.setting.cls_price;
        this.fee = this.value1 * this.setting.exchange_fee_ratio;
      } else {
        this.value2 = this.value1 / this.setting.cls_price;
        this.fee = this.value2 * this.setting.exchange_fee_ratio;
      }
    },
    // 资产列表
    getMyEarn() {
      this.$axios.get(this.$api.myEarn, {}).then((res) => {
        this.USDT = res.data.USDT;
        this.CLS = res.data.CLS;
        this.info = res.data;
      });
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    toRecord() {
      this.$router.push("/activeRecord?type=4");
    },
    //网站配置
    getsetting() {
      let info = JSON.parse(this.$store.state.setting);
      info.forEach((item) => {
        if (item.key == "cls_price") {
          this.setting.cls_price = item.value;
        }
        if (item.key == "exchange_fee_ratio") {
          this.setting.exchange_fee_ratio = item.value;
        }
        if (item.key == "cls_tiqu") {
          this.setting.cls_tiqu = item.value;
        }
      });
    },
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    this.getsetting();

    this.getMyEarn();
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 9px 15px;
  .top-card {
    background: url("../assets/cls/deal-card-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 17px 16px;
    .name {
      display: flex;
      align-items: center;
      span {
        margin-left: 6px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
      margin-bottom: 10px;
    }
    .price {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
    }
  }

  .overview {
    margin: 8px 0 12px;
    padding: 20px 15px;
    background: #f2f9ff;
    border-radius: 10px;
    .conversion {
      display: flex;
      justify-content: space-between;
      margin-bottom: 42px;
      > div:first-child {
        div:first-child {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #00a8ff;
        }
        div {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-top: 5px;
          color: #7091b1;
        }
      }
    }
    .quota {
      display: flex;
      justify-content: space-between;
      div:first-child {
        font-size: 12px;
        font-family: Source Han Sans CN;
        margin-bottom: 18px;
        font-weight: 400;
        color: #7091b1;
      }
      div:last-child {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #00a8ff;
      }
    }
  }
  .bottom-card {
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
    border-radius: 10px;
    padding: 16px 16px 20px;
    .currency {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;
      > div:first-child {
        display: flex;
        align-items: center;

        span {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          margin-left: 8px;
        }
      }
      > div:last-child {
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
    .van-field {
      padding: 10px 10px 40px;
      background: #f8f8f8;
      border-radius: 5px;
      position: relative;
      .btns {
        display: flex;
        position: absolute;
        bottom: 13px;
        right: 10px;
        .btn {
          width: 41px;
          height: 21px;
          background: #ecf5ff;
          border: 1px solid #00a8ff;
          border-radius: 5px;
          margin-right: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #00a8ff;
        }
      }
    }
    .change-btn {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 0px 1px 0px #aaaaaa;
      border-radius: 50%;
      margin: 9px auto;
    }
  }
  .price {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #00a8ff;
  }
  .service {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin: 8px 0 11px;
  }
}
</style>